/** @jsxImportSource theme-ui */

import { graphql, HeadFC, Link } from "gatsby";
import type { PageProps } from "gatsby";
import { BaseStyles, ThemeUICSSObject } from "theme-ui";
import ExternalLink from "../components/external-link";
import Global from "../components/global";
import { Header } from "../components/header";
import Main from "../components/main";
import RecentList from "../components/recent-list";
import Seo from "../components/seo";
import Wrapper from "../components/wrapper";
import { createMyDate, createTime, MyDate } from "../utils/datetimeutils";

type DataNodeWithObjects = {
  node: {
    name?: string | null;
    namePrefix?: string | null;
    nameSuffix?: string | null;
    canceled?: string | null;
    locationName?: string | null;
    locationSubName?: string | null;
    program?: string | null;
    cast?: string | null;
    content?: string | null;
    note?: string | null;
    sources?: string | null;

    dateObj?: MyDate;
    endDateObj?: MyDate;
    openTimeObj?: Date;
    startTimeObj?: Date;
    endTimeObj?: Date;
  };
};

interface VisitListEntry {
  text: string;
  url?: string;
}

export const Head: HeadFC<Queries.TaikaiPostQuery> = ({ data }) => {
  var meta = data.googleMetaSheet;
  var title = `${meta?.namePrefix || ""}${meta?.nameOfficial || ""}${meta?.nameSuffix || ""}の日程`;

  return <Seo title={title} article={true} />;
};

const TaikaiPost = ({ data }: PageProps<Queries.TaikaiPostQuery>) => {
  // TODO: overwrite with default values from meta sheet

  var meta = data.googleMetaSheet;
  var title = `${meta?.namePrefix || ""}${meta?.nameOfficial || ""}${meta?.nameSuffix || ""}`;
  var lastModifiedObj = new Date(meta?.lastModified ?? "");
  var lastModified = `${lastModifiedObj.getFullYear()}年${lastModifiedObj.getMonth() + 1}月${lastModifiedObj.getDate()}日 `
    + `${String(lastModifiedObj.getHours()).padStart(2, "0")}:${String(lastModifiedObj.getMinutes()).padStart(2, "0")}`;
  const description = meta?.descriptionDefault;
  const note = meta?.noteDefault;
  var visitList: VisitListEntry[] = [];
  if (meta?.visitList) {
    visitList = meta.visitList.split("\n").map(v => {
      const s = v.split("http"); // should work for both http and https
      if (s.length === 2) {
        const text = s[0];
        const url = `http${s[1]}`;
        return {
          text: text,
          url: url,
        };
      } else {
        return {
          text: v,
        };
      }
    });
  }

  const dataWithObjects: DataNodeWithObjects[] = data.allGoogleDataSheet.edges.map(({ node }) => {
    const d: DataNodeWithObjects = {
      node: node,
    };
    d.node.dateObj = createMyDate(node.date, node.isFest);
    d.node.endDateObj = createMyDate(node.endDate, node.isEndDateFest);
    d.node.openTimeObj = createTime(node.openTime);
    d.node.startTimeObj = createTime(node.startTime);
    d.node.endTimeObj = createTime(node.endTime);
    return d;
  });

  return (
    <>
      <Global />
      <Header />
      <Main>
        <Wrapper>
          <BaseStyles>
            <h1>{title}{meta?.myNameSuffixDefault && <span sx={titleSuffix}>{meta.myNameSuffixDefault}</span>}</h1>
            <div sx={lastMod}>{"最終更新日時：" + lastModified}</div>
            {description
              ? (
                <>
                  <h2>概要</h2>
                  <p>{description}</p>
                </>
              )
              : ""}
            <h2>直近の開催日程</h2>
            <div sx={{ marginTop: "1.5rem" }}>
              <RecentList data={dataWithObjects} locationsData={data.allGoogleLocationSheet.edges} />
            </div>
            {note
              ? (
                <>
                  <h2>備考</h2>
                  <div>{note}</div>
                </>
              )
              : ""}
            {visitList.length > 0
              ? (
                <>
                  <h2>過去の見学メモ</h2>
                  <ul sx={visitUl}>
                    {visitList.map(v => {
                      if (v.url) {
                        return (
                          <li key={v.url}>
                            <ExternalLink url={v.url}>{v.text}</ExternalLink>
                          </li>
                        );
                      } else {
                        return <li key={v.text}>{v.text}</li>;
                      }
                    })}
                  </ul>
                </>
              )
              : ""}
            <div sx={{ marginTop: "2.0rem", marginBottom: "2.0rem" }}>
              ※ 悪天候などの理由で予定が変更や中止になることもあります。お出かけの際は主催者の情報をお確かめ下さい。<br />
              古いリンクはリンク切れになっていることがありますがご了承ください。
            </div>
            <Link to="/">トップページに戻る</Link>
          </BaseStyles>
        </Wrapper>
      </Main>
    </>
  );
};

export default TaikaiPost;

const titleSuffix = {
  fontSize: "80%",
};

const lastMod: ThemeUICSSObject = {
  fontSize: "80%",
  marginBottom: "2.0rem",

  "@media screen and (min-width: 601px)": {
    textAlign: "right",
  },
};

const visitUl = {
  listStyleType: "disc",

  "li": {
    marginLeft: "1.5rem",
    marginTop: "0.3rem",
  },
};

export const query = graphql`
  query TaikaiPost($taikaiId: String!) {
    googleMetaSheet(taikaiId: {eq: $taikaiId}) {
      taikaiId
      namePrefix
      nameOfficial
      nameSuffix
      myNameSuffixDefault
      descriptionDefault
      noteDefault
      visitList
      lastModified
    }
    allGoogleDataSheet(filter: {taikaiId: {eq: $taikaiId}}) {
      edges {
        node {
          taikaiId
          name
          namePrefix
          nameSuffix
          canceled
          date
          isFest
          endDate
          isEndDateFest
          openTime
          startTime
          endTime
          locationName
          locationSubName
          program
          cast
          content
          note
          sources
        }
      }
    }
    allGoogleLocationSheet {
      edges {
        node {
          locationName
          searchName
          locationAddress
        }
      }
    }
  }
`;
